import styled from "styled-components";
import { CSSVars } from "../../styles/styles.config";
import { CustomLink } from "../link/link.comp";

export const StyledWidgetNav = styled.nav`
  transition: 0.2s;

  opacity: 0;
  pointer-events: none;

  &.show {
    opacity: 1;
    pointer-events: all;
    height: unset;
  }

  &.fixed {
    position: fixed;
    z-index: 100;

    top: 10px;
    left: 10px;
    right: 10px;

    /* background: #ffffff; */

    .links {
      background-color: ${CSSVars.black};
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
      margin: 0 auto;
      padding: 15px 20px;
      max-width: 1320px;
      border-radius: 17px;
      border: 1px solid #5f5f5f;
      font-size: 15px;

      .logo {
        color: ${CSSVars.white};
      }

      a {
        color: ${CSSVars.gray200};

        &.active,
        &:hover {
          color: ${CSSVars.white};
        }
      }
    }
  }

  &.fixed {
    .links {
      @media screen and (min-width: ${CSSVars.breakpointMD}) {
        justify-content: space-between;
      }
    }
  }

  .links {
    padding: 15px 0;
    max-width: 1320px;
    display: flex;
    justify-content: center;
    margin: 0 auto 30px;
    font-size: 15px;

    @media screen and (min-width: ${CSSVars.breakpointMD}) {
      margin: 30px auto 10px;

      &.fixed {
        justify-content: space-between;
      }
    }

    .examples {
      display: none;

      @media screen and (min-width: ${CSSVars.breakpointMD}) {
        display: block;
      }
    }

    .how-to-add {
      display: none;

      @media screen and (min-width: ${CSSVars.breakpointMD}) {
        display: block;
      }
    }

    .templates {
      display: none;

      @media screen and (min-width: ${CSSVars.breakpointMD}) {
        display: block;
      }
    }

    .logo {
      color: ${CSSVars.white};
      align-items: center;
      gap: 20px;
      font-size: ${CSSVars.fontSizeMD};

      display: none;

      @media screen and (min-width: ${CSSVars.breakpointMD}) {
        display: flex;
      }

      img {
        border-radius: 50%;
      }
    }

    ul {
      display: flex;
      align-items: center;
      gap: 30px;

      @media screen and (min-width: ${CSSVars.breakpointMD}) {
        gap: 50px;
      }

      a {
        color: ${CSSVars.white};
        font-weight: 300;
        transition: 0.2s;
        opacity: 0.7;
        border-color: ${CSSVars.white};

        &.active,
        &:hover {
          opacity: 1;
          border-bottom: 1px solid ${CSSVars.white};
        }
      }

      .cta-li a {
        font-size: ${CSSVars.fontSizeSM};
        background-color: transparent;
        color: ${CSSVars.white};
        border: 1px solid ${CSSVars.white};
        opacity: 1;
        transition: 0.2s;

        @media screen and (min-width: ${CSSVars.breakpointMD}) {
          font-size: ${CSSVars.fontSizeMD};
        }

        &:hover {
          background: linear-gradient(
            269deg,
            rgba(76, 79, 255, 0) 0%,
            rgba(76, 79, 255, 0.6) 100%
          );
          border-color: ${CSSVars.blue400};
        }
      }
    }
  }
`;

export const StyledFloatingButton = styled.a`
  position: fixed;
  left: 50%;
  bottom: 30px;
  z-index: 100;
  transform: translateX(-50%);
  font-size: ${CSSVars.fontSize2XL};
  scale: 0.8;
  padding: 10px 40px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  transform-origin: 0% 50%;
  cursor: pointer;
  background-color: ${CSSVars.blue500};
  border: none;
  color: white;
  transition: 0.2s;
  font-weight: 500;
  font-family: ${CSSVars.primaryFont};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: fit-content;

  @media screen and (max-width: ${CSSVars.breakpointMD}) {
    font-size: ${CSSVars.fontSizeXL};
    padding: 10px 30px;
    scale: 1;
  }

  &:hover {
    background-color: ${CSSVars.blue700};
    color: white;
  }

  &:active {
    background-color: ${CSSVars.blue300};
    color: white;
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &.shake {
    animation: tilt-shaking 0.15s linear infinite;
  }

  @keyframes tilt-shaking {
    0% {
      rotate: 0deg;
    }
    25% {
      rotate: 5deg;
    }
    50% {
      rotate: 0deg;
    }
    75% {
      rotate: -5deg;
    }
    100% {
      rotate: 0deg;
    }
  }
`;
